import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const useAiAgent = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const createLead = async (data, cb) => {
    setError("");
    setLoading(true);
    try {
      const res = await axios.post(
        `https://us-api.thesales.io/v1/public/add_lead?type=custom`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        },
      );
      if (res.statusText !== "OK")
        throw new Error(res.msg || "Some error occured, please try again");
      if (cb && typeof cb === "function") cb(res.data, null);
    } catch (err) {
      setError(err.message);
      cb(null, err);
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    createLead,
  };
};

export default useAiAgent;
