import styles from "./TopFeatures.module.scss";

import { top_features } from "../../../../constants";

import { Button, VerticalCard } from "../../../../components";

const TopFeatures = ({ features }) => {
  return (
    <div className={styles.features}>
      <div className={styles.features_text}>
        <h1 className={styles.features_title}>
          AI CALLS - <span>top features</span>
        </h1>
        <p className={styles.features_desc}>
          With our AI agents, you can automate all repetative tasks, so that you
          can focus more on your business goals.
        </p>
      </div>
      <div className={styles.features_container}>
        <div className={styles.features_list}>
          {features?.map((feature) => (
            <VerticalCard
              key={feature.id}
              title={feature.title}
              desc={feature.description}
              imageAbsent
            />
          ))}
        </div>
      </div>

      <Button
        text={"Signup"}
        link={"https://app.aicalls.io/signup"}
        padding={"1rem 0"}
        width={"10.8rem"}
        borderRadius={"2rem"}
      />
    </div>
  );
};

export default TopFeatures;
