import { useState } from "react";
import { toast } from "react-toastify";
import { AsYouType } from "libphonenumber-js";

import styles from "./AiFeatures.module.scss";

// apis
import useAiAgent from "../../apis/useAiAgent";

// components
import { Form } from "./components";

// constants
import { aiautodial } from "../../constants";

// campaigns to add in 
const campaign_info = {
  service_booking: {
    campaign_name: "Service Booking",
    campaign_id: 873,
  },
  appointment_booking: {
    campaign_name: "Appointment Booking",
    campaign_id: 874,
  },
  customer_satisfaction: {
    campaign_name: "Customer Satisfaction",
    campaign_id: 875,
  }
};

const AiFeatures = ({
  headline,
  desc,
  features,
  custom_styles,
  showCrm = true,
  showCampaignTypes = false,
}) => {
  const { createLead, loading } = useAiAgent();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    isAgreed: false,
    campaignInfo: campaign_info.service_booking,
  });

  const clean = (str) => {
    return str.split(" ").join("");
  };

  const handlePhoneNumber = (event) => {
    const input = event.target;
    var value = event.target.value;
    const cursorStart = input.selectionStart;

    let newCursorPos = cursorStart;
    let cleanedValue = clean(value);
    let regExp = /^\d+$/;
    let check = cleanedValue.slice(1);

    if (value.startsWith("+") && regExp.test(check)) {
      const formattedNumber = new AsYouType().input(cleanedValue);

      if (clean(formattedNumber).length > clean(formData.phone).length) {
        let charactersAtStart = clean(value.substring(0, cursorStart)).length;
        let count = 0;
        let i = 0;

        while (i < formattedNumber.length) {
          if (formattedNumber[i] !== " ") {
            count++;
          }

          if (count === charactersAtStart) {
            newCursorPos = i + 1;
            break;
          }

          i++;
        }

        setFormData((prev) => {
          return {
            ...prev,
            phone: formattedNumber,
          };
        });

        setTimeout(() => {
          input.setSelectionRange(newCursorPos, newCursorPos);
        }, 1);
      } else {
        setFormData((prev) => {
          return {
            ...prev,
            phone: value,
          };
        });
      }
    } else if (regExp.test(value)) {
      check = value;
      value = "+" + value;
      setFormData((prev) => {
        return {
          ...prev,
          phone: new AsYouType().input(value),
        };
      });
    } else if (value !== "") {
      setFormData((prev) => {
        return {
          ...prev,
          phone: value,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          phone: value,
        };
      });
    }
  };

  const handleFormSubmit = async () => {
    // check if name, phone number, and email are provided
    if (!formData.name || !formData.phone || !formData.email) {
      toast.warn("Please provide a name, email, and phone number to proceed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    // check for campaign
    if (!formData.campaignInfo) {
      toast.warn("Please select a campaign to proceed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    // check for valid email address
    const regExpForEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regExpForEmail.test(formData.email)) {
      toast.warn("Please provide a valid email address to proceed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    // check length of phone
    if (formData.phone.length < 10) {
      toast.warn("Please provide a valid phone number to proceed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    // check if phone number starts with a plus sign or has a valid country code
    const regExpForPhone = /^\+\d+/;
    if (!regExpForPhone.test(formData.phone)) {
      toast.warn(
        "Please provide a phone number with a valid country code (+)",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        },
      );
      return;
    }

    // check if lead has accepted terms and conditions
    if (!formData.isAgreed) {
      toast.warn("Please accept terms and conditions to proceed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    // format the data
    const lead_data = {
      campaign_id: formData?.campaignInfo?.campaign_id,
      leads: [
        {
          first_name: formData?.name.split(" ")[0],
          last_name: formData?.name.split(" ")[1] || "",
          primary_phone: formData?.phone,
          primary_email: formData?.email,
        },
      ],
    };

    // send the lead to the campaign
    await createLead(lead_data, (data, error) => {
      if (error) {
        toast.error(
          "Some error occured, please try again or contact support team",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          },
        );
        return;
      }

      toast.success("Callback initiated", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    });
  };

  return (
    <div className={styles.aif + " " + custom_styles}>
      <div className={styles.features_form}>
        <div className={styles.features}>
          {/* heading */}
          {headline ? (
            <h1>
              <span>{headline}</span>
            </h1>
          ) : (
            <h1>
              <span>AI CALLS</span> Top features
            </h1>
          )}

          {/* paragraph */}
          {desc ? (
            <p>{desc}</p>
          ) : (
            <p>
              With AI CALLS you can create custom AI Voice agents for your
              business. Setting up AI voice agent is{" "}
              <span>simple and easy.</span>
            </p>
          )}

          {/* points and features */}
          <div className={styles.points}>
            {features?.map((feature, index) => (
              <div key={index} className={styles.point}>
                <div className={styles.circles}>
                  <div className={styles.outer_circle}>
                    <div className={styles.inner_circle} />
                  </div>
                </div>
                <h3>{feature.title}</h3>
              </div>
            ))}
          </div>

          {/* hashtags */}
          <h4 className={styles.hastags}>
            #Appointment Settings #Customer Support #Lead Validation #Welcome
            Calls and much more....
          </h4>
        </div>
        <div className={styles.form}>
          <Form
            formData={formData}
            setFormData={setFormData}
            handleSubmit={() => {
              handleFormSubmit();
            }}
            handlePhoneNumber={handlePhoneNumber}
            loading={loading}
            campaignInfo={campaign_info}
            showCampaignTypes={showCampaignTypes}
          />
        </div>
      </div>
      {showCrm && (
        <div className={styles.integrations}>
          {/* svgs */}
          <div className={styles.rec1}>
            <div className={styles.outer_rec1}>
              <div className={styles.inner_rec1} />
            </div>
          </div>

          {/* header */}
          <div className={styles.header}>Easy integration with</div>

          {/* images */}
          <div className={styles.crm}>
            <div className={styles.title}></div>
            <div className={styles.logos}>
              {aiautodial.integration_crms.map((crm, index) => (
                <>
                  <img src={crm.logo} alt={crm.name} key={crm.id} />
                  {/* not to show if last crm */}
                  {index !== aiautodial.integration_crms.length - 1 && (
                    <div className={styles.circles}>
                      <div />
                      <div />
                      <div />
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>

          {/* para */}
          <div className={styles.bottom}>
            <p>and more…</p>
          </div>

          {/* svgs */}
          <div className={styles.outer_rec2}>
            <div className={styles.inner_rec2} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AiFeatures;
