import React from "react";

import styles from "./Button.module.scss";

const Button = ({
  text,
  padding,
  width,
  borderRadius,
  icon,
  onClick,
  custom_styles,
}) => {
  return (
    <button
      className={styles.button + " " + custom_styles}
      onClick={
        onClick
          ? onClick
          : () => {
              console.log("Button Clicked");
            }
      }
      style={{
        padding: padding ? padding : "0.5rem 1rem",
        ...(width && { width }),
        ...(borderRadius && { borderRadius }),
      }}
    >
      {icon && (
        <div className={styles.icon}>
          <img src={icon} alt="icon" />
        </div>
      )}
      <p className={styles.text}>{text ? text : "Button"}</p>
    </button>
  );
};

export default Button;
