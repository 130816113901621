// styles
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
  return (
    <>
      <div className={styles.privacy_container}>
        <h1>Our Privacy Policy</h1>
        <div>
          <p>
            Privacy and security are of utmost importance to AI CALLS and we
            strive to ensure that our technical and organisational measures in
            place respect your data protection rights.
          </p>
          <p>
            This Privacy Policy describes how we manage, process and store
            personal data submitted in the context of providing our services. “
            <strong>
              <i>Personal data</i>
            </strong>
            ” refers to any information relating to an identifiable individual
            or his or her personal identity.
          </p>
          <h2>Consent</h2>
          <p>
            In subscribing to our services or filling in a contact form on our
            website (<a href="_wp_link_placeholder">www.aicalls.io</a>) you
            agree and accept that we may gather, process, store and/or use the
            personal data submitted in accordance with the rules set forth
            below.
          </p>
          <p>
            By giving your consent to us, you also retain the right to have your
            personal data rectified, to be forgotten and/or to be erased.
          </p>
          <h2>Personal Data Collected</h2>
          <h3>Identity and contact details of the data processor</h3>
          <p>
            Personal data is collected on our website by AI CALLS SASS, a
            company registered under the laws of France.
          </p>
          <h3>Data Protection Authority Declarations</h3>
          <p>
            Declarations on the automated processing of data have been
            registered with the French chmeber of commerce.
          </p>
          <p>
            You retain the right to lodge any complaints on data protection with
            this supervisory authority.
          </p>
          <h3>Data collected on the site</h3>
          <p>
            When you use our platform and/or when you register, we collect and
            process your personal data such as your first name when you create
            an account.
          </p>
          <p>
            We also ask you to give us your email so we can use this data to
            create an account, confirm this account, send informational and
            transactional emails as well as our newsletter.
          </p>
          <p>
            By using our services, the following data is collected and managed:
            log-on data and browsing data where you authorise it, order history,
            complaints, incidents, information on subscriptions and messages on
            our site. Some data is collected automatically by reason of your
            activity on the site (see paragraph on cookies below).
          </p>
          <p>
            When you register on the platform, you can register through email
            verification. We also may collect certain information such as
            'company details'.
          </p>
          <h3>Purposes of processing and legal basis</h3>
          <p>
            The principal purpose of collecting your personal data is to offer
            you a safe, optimum, efficient and personalised experience. To this
            end, you agree and accept that we may use your personal data to:
          </p>
          <ul>
            <li>
              provide our services and facilitate performance, including
              verifications relating to you;
            </li>
            <li>
              resolve any problems so as to improve the use of our site and
              services;
            </li>
            <li>
              personalise, assess, and improve our services, content and
              materials;
            </li>
            <li>analyse the volume and history of your use of our services;</li>
            <li>
              inform you about our services as well as our partners’ services
              and/or promotional offers;
            </li>
          </ul>
          <p>
            We use the personal data submitted to us only in accordance with the
            applicable data protection legislation. For our clients who have
            signed up on our website, we processes your personal data for the
            performance of the contract between us to provide our services.
          </p>
          <p>
            For our newsletter, use case studies and marketing material sign
            ups, we process your personal data based on the express consent you
            provide for this specific purpose.
          </p>
          <h3>Third party disclosures</h3>
          <p>
            Personal data relating to you collected on our website are destined
            for AI CALLS own use and may be forwarded to AI CALLS partner
            companies so that we may obtain assistance and support in the
            context of carrying out our services. AI CALLS ensures that it has
            in place clear data protection requirements for all of its third
            party providers.
          </p>
          <p>
            AI CALLS does not sell or rent your personal data to third parties
            for marketing purposes whatsoever.
          </p>
          <p>
            In addition, AI CALLS does not disclose your personal data to third
            parties, except if: (1) you (or your account administrator acting on
            your behalf) requests or authorises disclosure thereof; (2) the
            disclosure is required to process transactions or supply services
            which you have requested (i.e. to check you are employing best
            practice in your mailings or for the purposes of processing an
            acquisition card with credit-card issuing companies); (3) AI CALLS
            is compelled to do so by a government authority or a regulatory
            body, in the case of a court order, a summons to appear in court or
            any other similar requisition from a government or the judiciary, or
            to establish or defend a legal application; or, (4) the third party
            is a subcontractor or sub-processor of AI CALLS in the carrying out
            of services (for example AI CALLS uses the services of an Internet
            provider or a telecommunications company).
          </p>
          <h3>Access to your personal data and how is it transferred?</h3>
          <p>
            AI CALLS takes all necessary measures to ensure compliance with the
            GDPR and, therefore, the confidentiality of your personal data. For
            this reason, only a limited number of people can have access to your
            personal data, knowing that measures of Personal Data protection
            were set up with all these recipients, in particular by means of
            contractual provisions.
          </p>
          <p>
            In accordance with the French Data Protection Laws and the European
            General Data Protection Regulation 2016/679 (GDPR) you have a right
            of access, correction and removal of your personal data which you
            may exercise by sending us a support ticket at contact@AI CALLS.io.
            Your requests will be processed within 30 days
          </p>
          <p>
            "AI CALLS" uses sensitive Google scopes - Sensitive scopes allow
            access to Google User Data. If an app uses sensitive scopes, it must
            comply with the{" "}
            <a href="https://developers.google.com/terms/api-services-user-data-policy?sjid=10986073781743322668-EU">
              Google API User Data Policy
            </a>{" "}
            or product specific User Data policy and have its OAuth consent
            screen configuration verified by Google.
          </p>
          <p>
            AI CALLS" use and transfer to any other app of information received
            from Google APIs will adhere to
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google API Services User Data Policy{" "}
            </a>
            , including the Limited Use requirements.
          </p>
          <h3>Cookies/Tracking</h3>
          <p>
            As a general rule, AI CALLS uses cookies and tracking to improve and
            personalise its Website and/or measure its audience. Cookies are
            files saved on your computer’s hard drive when browsing on the
            Internet and in particular on our site. A cookie is not used to
            gather your personal data without your knowledge but instead to
            record information on site browsing which can be read directly by AI
            CALLS on your subsequent visits and searches on the site.
          </p>
          <p>
            You can choose to decline acceptance of all cookies, but your
            ability to browse certain pages of the site may be reduced. The
            cookies used by AI CALLS are intended to enable or facilitate
            communication, to enable the services requested by users to be
            supplied, to recognise users when they re-visit the site, to secure
            payments which users may make, to register the language spoken by
            users or other preferences necessary for the service requested to be
            supplied.
          </p>
          <p>
            By default, cookies are not installed automatically (with the
            exception of those cookies needed to run the site and AI CALLS
            services, and you are informed of their installation by a banner).
            In accordance with the regulations that apply, AI CALLS will require
            your authorisation before implanting any other kind of cookie on
            your hard drive. To avoid being bothered by these routine requests
            for authorisation and to enjoy uninterrupted browsing, you can
            configure your computer to accept AI CALLS cookies or we are able to
            remember your refusal or acceptance of certain cookies. By default,
            browsers accept all cookies.
          </p>
          <p>
            When you access third party sites directing you to our website, or
            when you are reading integration or social media links, cookies can
            be created by the companies disseminating these links. These third
            parties may be able to use cookies in the context of AI CALLS
            services (partners or other third parties supplying content or
            services available on the AI CALLS site) and are responsible for the
            cookies they install and it is their conditions on cookies which
            apply. AI CALLS assumes no liability in regard to the possible use
            of cookies by third parties. For more information, you are advised
            to check the cookie policy directly on these third party sites with
            regard to their use of cookies.
          </p>
          <h2>Data Retention Periods</h2>
          <p>
            AI CALLS collects your personal data for the requirements of
            carrying out its contractual obligations as well as information
            about how and when you use our services and we retain this data in
            active databases, log files or other types of files so long as you
            use our services.
          </p>
          <p>
            AI CALLS only stores your data for the time needed to provide to you
            our services, and your data will be deleted after closing your
            account (unless otherwise required by law). You are able to access
            your personal data for as long as you hold an active account with
            us.
          </p>
          <h2>Location of Data Storage and Transfers</h2>
          <p>
            The host servers on which AI CALLS processes and stores its
            databases are located exclusively within the European Union.
          </p>
          <p>
            AI CALLS will inform you immediately, to the extent we are legally
            authorised to do so, in case of any application or order originating
            from an administrative or judicial authority relating to your
            personal data.
          </p>
          <h2>Security</h2>
          <p>
            Within the framework of its services, AI CALLS attributes the very
            highest importance to the security and integrity of its customers’
            personal data.
          </p>
          <p>
            Thus and in accordance with the GDPR, AI CALLS undertakes to take
            all pertinent precautions in order to preserve the security of the
            data and, in particular, to protect them against any accidental or
            unlawful destruction, accidental loss, corruption, unauthorised
            circulation or access, as well as against any other form of unlawful
            processing or disclosure to unauthorised persons.
          </p>
          <p>
            To this end, AI CALLS implements industry standard security measures
            to protect personal data from unauthorised disclosure. In using
            industry recommended methods of encoding, AI CALLS takes the
            measures necessary to protect information connected with payments
            and credit cards.
          </p>
          <p>
            Moreover, in order to avoid in particular all unauthorised access,
            to guarantee accuracy and the proper use of the data, AI CALLS has
            put the appropriate electronic, physical and managerial procedures
            in place with a view to safeguarding and preserving the data
            gathered through its services.
          </p>
          <p>
            Notwithstanding this, there is no absolute safety from piracy or
            hackers. That is why in the event a breach of security were to
            affect you, AI CALLS undertakes to inform you thereof without undue
            delay and to use its best efforts to take all possible measures to
            neutralise the intrusion and minimise the impacts. Should you suffer
            any loss by reason of the exploitation by a third party of a
            security breach, AI CALLS undertakes to provide you with every
            assistance necessary so you are able to assert your rights.
          </p>
          <p>
            You should keep in mind that any user, customer or hacker who
            discovers and takes advantage of a breach in security renders him or
            herself liable to criminal prosecution and that AI CALLS will take
            all measures, including filing a complaint and/or bringing court
            action, to preserve the data and the rights of its users and of
            itself and to limit the impacts.
          </p>
          <h2>Privacy Policy Changes</h2>
          <p>
            AI CALLS reserves the right to update this Privacy Policy at any
            time, in particular, pursuant to any changes made to the laws and
            regulations in force. We would recommend that you check these rules
            from time to time to stay informed of our procedures and rules
            relating to your personal information.
          </p>
          <h2>Contact Us</h2>
          <p>
            Visit our contact page at{" "}
            <a href="https://www.aicalls.io/contact-us">
              https://www.aicalls.io/contact-us
            </a>{" "}
            if you have questions or you can email us directly at:{" "}
            <a href="mailto:contact@aicalls.io">contact@aicalls.io</a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
