// styles
import styles from "./VerticalCard.module.scss";

const VerticalCard = ({ title, desc, image, imageAbsent }) => {
  return (
    <div className={styles.card}>
      {!imageAbsent && (
        <div className={styles.card_img}>
          <img src={image} alt="card" />
        </div>
      )}
      <div className={styles.card_title}>
        <p>{title}</p>
      </div>
      <div className={styles.card_desc}>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default VerticalCard;
