import styles from "./Input.module.scss";

const Input = ({
  type,
  placeholder,
  value,
  onChange,
  heading,
  desc,
  disabled,
  id,
  name,
  input_info,
  className,

  // get error, info, success msg
  error,
  info,
  success,
  warning,

  // this is a spread operator. It is used to pass all the props from the parent component to the child component.
  ...inputProps
}) => {
  return (
    <div className={styles.input_card}>
      {heading && <div className={styles.header}>{heading}</div>}
      {desc && <p>{desc}</p>}
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={
          disabled
            ? disabled
            : error
            ? true
            : info
            ? true
            : success
            ? true
            : warning
            ? true
            : false
        }
        className={className}
        {...inputProps}
      />
    </div>
  );
};

export default Input;
