import { images } from ".";

const video_links = [
  "https://www.youtube.com/embed/9vew_b9do4A",
  "https://www.youtube.com/embed/94KBe24f0jY",
];

const book_demo = [
  {
    id: 1,
    title: "Early Bird Price:",
    description:
      "Get exclusive access to special discounted rates by joining the calling list early.",
  },
  {
    id: 2,
    title: "Stay Informed:",
    description:
      "Be the first to know about the latest updates and innovations.",
  },
  {
    id: 3,
    title: "Webinar and Training Invitations:",
    description:
      "Gain access to exclusive webinars and training sessions to enhance your knowledge.",
  },
];

const audio_sample = [
  {
    id: 1,
    title: "Advanced document retrival",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisi eget metus gravida fermentum. Nullam vitae nunc eget nunc aliquam ultrices. ",
    // audio: "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/Nick+wants+a+ferrari+(1).mp3"
    // audio:
    //   "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/Solar%20panel%20installation.mp3",
    audio:
      "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/Insurance_industry.mp3",
  },
  {
    id: 2,
    title: "Customer Support",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisi eget metus gravida fermentum. Nullam vitae nunc eget nunc aliquam ultrices. ",
    // audio: "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/Call%20Sample%20INsurance.mp3"
    audio:
      "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/TailorHenry.mp3",
  },
  {
    id: 3,
    title: "Appointment Settings",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisi eget metus gravida fermentum. Nullam vitae nunc eget nunc aliquam ultrices. ",
    // audio: "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/AI+Ecommerce+Sample+Eli.mp3"
    // audio: "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/AI+outbound+cold+call.mp3"
    // audio:"https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/Customer+Outbound+call.mp3"
    //   audio:
    //     "https://s3.eu-west-3.amazonaws.com/thesales.io-public/ai_calls_landing_assets/CustomerOutboundcall-compressed.mp3",

    audio:
      "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/Real_Estate_customer_Call.mp3",
  },
];

const working = [
  {
    id: 1,
    step: "Step 1 :-",
    title: "Give your AI agent a name, select your prefered human voice",
    icon: images.industry,
    color: "#1363E5",
  },

  {
    id: 2,
    step: "Step 2 :-",
    title: "Provide clear call objectives and instructions",
    icon: images.microphone,
    color: "#4A5563",
  },
  {
    id: 3,
    step: "Step 3 :-",
    title: "Select a campaign type - Outbound or Inbound",
    icon: images.customer,
    color: "#9234EA",
  },
  {
    id: 4,
    step: "Step 4 :-",
    title: "Launch campaign, measure results and improve prompts",
    icon: images.stats,
    color: "#FFAC4C",
  },
];

const features = [
  {
    id: 1,
    title: "24/7 Availability",
    description:
      "Our AI Agents will be always available for you and your customers.",
    image: images.availability,
  },
  {
    id: 2,
    title: "Seamless Implementation",
    description: "No technical skills required to start using our AI agents.",
    image: images.flash,
  },
  {
    id: 3,
    title: "Multi-language",
    description:
      "Our AI Agents are available in English, French, Spanish, etc.",
    image: images.language,
  },

  {
    id: 4,
    title: "Personalized Solutions",
    description: "We’ll help you create AI Agent that better fit your needs.",
    image: images.search,
  },
];

const top_features = [
  {
    id: 1,
    title: "Inbound/Outbound campaigns",
    description: "You can easily create both inbound or outbound campaigns",
  },
  {
    id: 2,
    title: "Complex querry handling",
    description:
      "AI agents can easily answer complex questions be it from a document or a custom link",
  },
  {
    id: 3,
    title: "Voice mail drop",
    description:
      "You can easily configure personalised VM drops with AI agents",
  },

  {
    id: 4,
    title: "Background noise removal",
    description:
      "AI agents can communicate in any background noise area, without any issue.",
  },
  {
    id: 5,
    title: "Workflows (demo booking, call transfers and more...)",
    description:
      "You can schedule a demo or transfer a call with advanced workflows",
  },
  {
    id: 6,
    title: "Lead Gen campaigns",
    description:
      "You can configure your AI agents to call specific leads at specific time depending on your requirements",
  },
];

const partners = [
  {
    id: 1,
    name: "Partner",
    logo: images.logo1,
  },
  {
    id: 2,
    name: "Partner",
    logo: images.logo2,
  },
  {
    id: 3,
    name: "Partner",
    logo: images.logo3,
  },
  {
    id: 4,
    name: "Partner",
    logo: images.logo4,
  },
];

const powered = [
  {
    id: 1,
    name: "Partner",
    logo: images.elevenlabs_logo,
  },
];

const accordion_data = [
  {
    id: 1,
    title: "What is AI CALLS ?",
    content:
      "With AI CALLS you can create virtual agents for outbound or inbound calls. You can select human-like voices, write objectives and call instructions and your AI agent is ready to make live calls. ",
    isOpen: false,
  },
  {
    id: 2,
    title: "Can the AI voice agent make outbound voice calls?",
    content:
      "Yes, the AI voice agent can easily make outbound calls. You can create outbound call campaigns with a CSV or directly import a list from your CRM. AI agent is 100% autonomous and can perform real-time calls just like a real human.",
    isOpen: false,
  },
  {
    id: 3,
    title: "Can the AI agent receive phone calls?",
    content:
      "Yes, your AI agent is 100% capable of taking customer phone calls and engaging with humans. ",
    isOpen: false,
  },
  {
    id: 4,
    title: "Can AI agents transfer a call to a live human agent?",
    content:
      "Yes, with workflows, your AI agents can easily transfer a live call to a human agent. ",
    isOpen: false,
  },
  {
    id: 5,
    title: "Can AI agents transfer a call to a live human agent?",
    content:
      "Yes, with workflows, your AI agents can easily transfer a live call to a human agent.",
    isOpen: false,
  },
  {
    id: 6,
    title: "Can AI agents book demos and make outbound sales calls?",
    content:
      "AI agents are highly capable of making prospecting calls and booking demos with available time slots. You can use workflows for booking demos as per your availability and time zones.",
    isOpen: false,
  },
  {
    id: 7,
    title: "Can AI agents transfer a call to a live human agent?",
    content:
      "Yes, with workflows, your AI agents can easily transfer a live call to a human agent. ",
    isOpen: false,
  },
  {
    id: 8,
    title: "Do the AI voice agents sound like humans?",
    content:
      "Yes, we offer very human-like voices. You can choose from different male and female voices that suit your business needs.",
    isOpen: false,
  },
  {
    id: 9,
    title: "Do I need to write code?",
    content:
      "No, not a single line. You just write the call objective and call instructions, select a voice and you are ready to launch your campaign.",
    isOpen: false,
  },
  {
    id: 10,
    title: "Do I get a phone number?",
    content:
      "Yes, you can get your business phone number. Once you create an account with us, you can choose numbers as per your preference. ",
    isOpen: false,
  },
  {
    id: 11,
    title: "Can the AI agents work as customer support agents?",
    content:
      "Yes, you can create campaigns with the call objective for customer support. The AI agents can take customer objectives and respond to them easily.",
    isOpen: false,
  },
];

export {
  partners,
  features,
  working,
  audio_sample,
  book_demo,
  video_links,
  powered,
  top_features,
  accordion_data,
};
