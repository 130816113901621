import { images } from ".";

const video_links = [
  "https://www.youtube.com/embed/9vew_b9do4A",
  "https://www.youtube.com/embed/94KBe24f0jY",
];

const book_demo = [
  {
    id: 1,
    title: "Early Bird Price:",
    description:
      "Get exclusive access to special discounted rates by joining the calling list early.",
  },
  {
    id: 2,
    title: "Stay Informed:",
    description:
      "Be the first to know about the latest updates and innovations.",
  },
  {
    id: 3,
    title: "Webinar and Training Invitations:",
    description:
      "Gain access to exclusive webinars and training sessions to enhance your knowledge.",
  },
];

const audio_sample = [
  {
    id: 1,
    title: "Inbound leads",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisi eget metus gravida fermentum. Nullam vitae nunc eget nunc aliquam ultrices. ",
    audio:
      "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/AI%20outbound%20cold%20call.mp3?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAgaCWV1LXdlc3QtMyJIMEYCIQCOPyCYCGlV886GenytBAtfvIAC2ReNqlAMbmXyBioFNQIhAM2%2F7Hvyzc0DrN9459ho2bG%2FP3AwPjrmyWItNI7EVyw0Ku0CCKH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMNDk5Njg5NTAzNDEzIgyWMcp%2BDJu0%2Bj7v1gYqwQKBJpb%2B1MF27hPLhBfAMQXluixcEOmUQ6ekujVbrV3%2BaBVNBxsPM1vGa0%2Bebfty4vX4XSVLVfBX28XZ6o7GYA8L52wu46uD5DL1xrkzs1TGUF8N%2FB0jO3JyrjIWPvv5PZMY2kh7Eyuls2seKFWnyHgGqzNsCYwTor%2BV2bRvjnV%2FQBI%2FD6qrf%2FGqxYJS11UsNGqDeX5CxV0LsSeM%2FNrxD%2FH38OmHpeQ%2BBKcbLAfYYSQNZ5BwicVvHhybB1aQHgvN81IcC6WzAzK53TfGG%2FpPxH%2FFNw5dL4ZKlYYOaDq6e81Y0bB0fqQr6HOfQwFciPr4pELsP0Njrp9FTRm3kAk%2Fw9LnnYLLUOYT5wg3mekX8MeBUxvHa920W3ar5cFj8hSE6STW656E6iAIRu049WDvWqDZ5ei%2F1a35cYfZjnOt6B0ZUOQwia%2BYpgY6sgIh%2BftmmlsSmfcqaQAxk44uwmUx2BygFF5pi1FgqtK%2FBxJYGGn1rezEsFv0Wqzauwj5FdU%2FKhAUEb%2BREcQVHQgRmPGweDwqod29ouDuRHczeRrT3axc4s5QG8PZp9p2Bm%2BHmsIfWUwxH%2BM6Jr4jPkJtFqk4xNhzvrkGaaE0Swv9j9Iry4MFBC0zxs%2BfJR%2FS7%2BcOnog0B0OjTHWHjIzgZ8P58Md6dax8wj0HYrOAIh6uGe12eH36%2FlQJBqaDmV5nLW7u6kmHsS%2B4GqWEskKiokdo14rozBb3%2FZPsI31U8TejWCVE5cC20S8TEhG20%2FdDG9ira8bhAXAKARAypNqIDQwqwyCnYdx2%2BRcwhjWiy%2FQBLXrrkHwZJ9Wxv8Z7zRDRN5ZU1w%2FBnjhjWJw9%2B9CicW2%2FKp0%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230730T075612Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAXIV6QXK22ZRAHKH4%2F20230730%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=0c790b092d4d2152aed09f5392cfbb3145a57416d69931d3cd2609036e9afd64",
  },
  {
    id: 2,
    title: "Customer support",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisi eget metus gravida fermentum. Nullam vitae nunc eget nunc aliquam ultrices. ",
    audio: "",
  },
  {
    id: 3,
    title: "Outbound sales",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisi eget metus gravida fermentum. Nullam vitae nunc eget nunc aliquam ultrices. ",
    audio:
      "https://alloblue-audios.s3.us-west-2.amazonaws.com/AI_CAlls_Sample_Audio/AI%20outbound%20cold%20call.mp3?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAgaCWV1LXdlc3QtMyJIMEYCIQCOPyCYCGlV886GenytBAtfvIAC2ReNqlAMbmXyBioFNQIhAM2%2F7Hvyzc0DrN9459ho2bG%2FP3AwPjrmyWItNI7EVyw0Ku0CCKH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMNDk5Njg5NTAzNDEzIgyWMcp%2BDJu0%2Bj7v1gYqwQKBJpb%2B1MF27hPLhBfAMQXluixcEOmUQ6ekujVbrV3%2BaBVNBxsPM1vGa0%2Bebfty4vX4XSVLVfBX28XZ6o7GYA8L52wu46uD5DL1xrkzs1TGUF8N%2FB0jO3JyrjIWPvv5PZMY2kh7Eyuls2seKFWnyHgGqzNsCYwTor%2BV2bRvjnV%2FQBI%2FD6qrf%2FGqxYJS11UsNGqDeX5CxV0LsSeM%2FNrxD%2FH38OmHpeQ%2BBKcbLAfYYSQNZ5BwicVvHhybB1aQHgvN81IcC6WzAzK53TfGG%2FpPxH%2FFNw5dL4ZKlYYOaDq6e81Y0bB0fqQr6HOfQwFciPr4pELsP0Njrp9FTRm3kAk%2Fw9LnnYLLUOYT5wg3mekX8MeBUxvHa920W3ar5cFj8hSE6STW656E6iAIRu049WDvWqDZ5ei%2F1a35cYfZjnOt6B0ZUOQwia%2BYpgY6sgIh%2BftmmlsSmfcqaQAxk44uwmUx2BygFF5pi1FgqtK%2FBxJYGGn1rezEsFv0Wqzauwj5FdU%2FKhAUEb%2BREcQVHQgRmPGweDwqod29ouDuRHczeRrT3axc4s5QG8PZp9p2Bm%2BHmsIfWUwxH%2BM6Jr4jPkJtFqk4xNhzvrkGaaE0Swv9j9Iry4MFBC0zxs%2BfJR%2FS7%2BcOnog0B0OjTHWHjIzgZ8P58Md6dax8wj0HYrOAIh6uGe12eH36%2FlQJBqaDmV5nLW7u6kmHsS%2B4GqWEskKiokdo14rozBb3%2FZPsI31U8TejWCVE5cC20S8TEhG20%2FdDG9ira8bhAXAKARAypNqIDQwqwyCnYdx2%2BRcwhjWiy%2FQBLXrrkHwZJ9Wxv8Z7zRDRN5ZU1w%2FBnjhjWJw9%2B9CicW2%2FKp0%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230730T075612Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAXIV6QXK22ZRAHKH4%2F20230730%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=0c790b092d4d2152aed09f5392cfbb3145a57416d69931d3cd2609036e9afd64",
  },
];

const working = [
  {
    id: 1,
    step: "Step 1 :-",
    title: "Import your leads from CSV or CRM",
    icon: images.industry,
    color: "#1363E5",
  },

  {
    id: 2,
    step: "Step 2 :-",
    title: "Setup call campaign with call dispositions",
    icon: images.microphone,
    color: "#4A5563",
  },
  {
    id: 3,
    step: "Step 3 :-",
    title: "Select your numbers",
    icon: images.customer,
    color: "#9234EA",
  },
  {
    id: 4,
    step: "Step 4 :-",
    title: "Start calling",
    icon: images.stats,
    color: "#FFAC4C",
  },
];

const features = [
  {
    id: 1,
    title: "Multiple Parallel Dialer",
    description:
      "Control dialing speed dialing single or multiple numbers at once.",
    image: images.availability,
  },
  {
    id: 2,
    title: "Voicemail Drop and Emailsp",
    description:
      "Leave pre-recorded voicemails and send templated emails seamlessly.",
    image: images.flash,
  },
  {
    id: 3,
    title: "AI Insights",
    description: "Get AI driven actionable insights to book more demos.",
    image: images.language,
  },

  {
    id: 4,
    title: "CRM Integration Made Easy",
    description:
      "Seamlessly integrate with top CRMs like Salesforce, Freshsales, and more.",
    image: images.search,
  },
];

const partners = [
  {
    id: 1,
    name: "Partner",
    logo: images.logo1,
  },
  {
    id: 2,
    name: "Partner",
    logo: images.logo2,
  },
  {
    id: 3,
    name: "Partner",
    logo: images.logo3,
  },
  {
    id: 4,
    name: "Partner",
    logo: images.logo4,
  },
];

export { partners, features, working, audio_sample, book_demo, video_links };
