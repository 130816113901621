import styles from "./Features.module.scss";

import { images, powerDialFeatures as features } from "../../../../constants";

import { VerticalCard } from "../../../../components";

const Features = () => {
  return (
    <div className={styles.features}>
      <div className={styles.features_text}>
        <h1 className={styles.features_title}>
          The <span>favorite AI Power Dialer</span> of sales teams
        </h1>
        <p className={styles.features_desc}>
          With our power dialer, you can bypass IVRs, machines, voicemails etc
          and talk to real prospects
        </p>
      </div>
      <div className={styles.features_container}>
        <div className={styles.features_list}>
          {features.map((feature) => (
            <VerticalCard
              key={feature.id}
              title={feature.title}
              desc={feature.description}
              image={feature.image}
            />
          ))}
        </div>
        <div className={styles.features_roi}>
          <p className={styles.features_roi_title}>
            <span>Pre-Qualify your database</span>
          </p>
          <p className={styles.features_roi_desc}>
            Identify humans from answer machines and IVRs before calling. Save
            your reps countless hours
          </p>
          <div className={styles.features_roi_img}>
            <img src={images.prequalify} alt="performance" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
