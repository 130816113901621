import React from "react";

// sections
import {
  BookDemo,
  Features,
  Footer,
  Header,
  Working,
  Samples,
  Statistics,
  Video,
  CallToAction,
} from "./sections";

// styles
import styles from "./PowerDial.module.scss";

const PowerDial = () => {
  return (
    <div className={styles.landing}>
      <Header />
      <Features />
      <Working />
      {/* <Samples /> */}
      <Statistics />
      <BookDemo />
      <Video />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default PowerDial;
