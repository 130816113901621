import { images } from "../../../../constants";

import { Button } from "../../../../components";

import styles from "./ImgContentSection.module.scss";

const ImgContentSection = ({
  title,
  description,
  image,
  cta,
  reverse = false,
}) => {
  return (
    <div
      className={styles.stats}
      style={{
        flexDirection: reverse ? "row-reverse" : "row",
      }}
    >
      <div className={styles.stats_left}>
        <img src={image ? image : images.performance} alt="performance" />
      </div>
      <div className={styles.stats_right}>
        <h1 className={styles.stats_title}>
          {title ? title : "Customisable AI Voice Agents"}
        </h1>
        <p className={styles.stats_desc}>
          {description
            ? description
            : "Create custom AI voice agents for your car dealership. Setting up AI voice agent is simple and easy."}
        </p>
        <Button
          text={"Get started"}
          link={cta ? cta : "https://calendly.com/ai-calls-rdv/30min"}
        />
      </div>
    </div>
  );
};

export default ImgContentSection;
