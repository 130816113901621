import { useState } from "react";
import styles from "./Video.module.scss";

import { video_links } from "../../../../constants";

const Video = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleVideoChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className={styles.video_container}>
      <div className={styles.video_text}>
        <h1 className={styles.video_title}>
          Listen to what our <span>customers think</span> about us
        </h1>
        <p className={styles.video_desc}>
          Don’t believe us? Listen to what our customers have to say about us.
        </p>
      </div>
      <iframe
        title="YouTube Video"
        src={video_links[activeIndex]}
        loading="lazy"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <div className={styles.indicators}>
        {video_links.map((link, index) => (
          <div
            key={index}
            className={`${styles.indicator} ${
              activeIndex === index ? styles.active : ""
            }`}
            onClick={() => handleVideoChange(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Video;
