import { useState } from "react";
import styles from "./Video.module.scss";

import { automobile } from "../../../../constants";

const Video = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  console.log(automobile?.video_links[activeIndex]);

  const handleVideoChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className={styles.video_container}>
      <div className={styles.video_text}>
        <h1 className={styles.video_title}>
          Create your <span>Ai voice agents</span> under 2min
        </h1>
        <p className={styles.video_desc}>
          No coding skills required. Just create simple instructions, and our AI
          can handle complex conversation
        </p>
      </div>
      <iframe
        loading="lazy"
        width="941"
        height="529"
        src={
          automobile?.video_links[activeIndex] ||
          "https://www.youtube.com/embed/w_wWR8wcdDU"
        }
        title="Short Demo AI Calls EN"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>

      <div className={styles.indicators}>
        {automobile?.video_links?.map((link, index) => (
          <div
            key={index}
            className={`${styles.indicator} ${
              activeIndex === index ? styles.active : ""
            }`}
            onClick={() => handleVideoChange(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Video;
