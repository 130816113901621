import styles from "./Working.module.scss";

import { Timeline, HorizontalCard } from "../../../../components";
import { images, powerDialWorking as working } from "../../../../constants";

const Working = () => {
  return (
    <div className={styles.working}>
      <div className={styles.working_text}>
        <h1 className={styles.working_title}>
          How does it <span>work?</span>
        </h1>
        <p className={styles.working_desc}>
          {/* It’s easy and simple to create your AI agent. No technical knowledge
          required. */}
        </p>
      </div>
      <div className={styles.working_body}>
        <div className={styles.working_body_left}>
          <img src={images.powerdial_ai} alt="ai" />
          <div className={styles.rectangle}></div>
          <div className={styles.circle}></div>
        </div>
        <div className={styles.working_body_right}>
          <Timeline />
          <div className={styles.working_body_right_cards}>
            {working.map((work) => (
              <HorizontalCard
                key={work.id}
                title={work.title}
                step={work.step}
                color={work.color}
                img={work.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Working;
