import React, { useState, useRef, useEffect } from "react";
import styles from "./AudioCard.module.scss";
import { images } from "../../constants";
import WaveAnimation from "../WaveAnimation/WaveAnimation";

const AudioCard = ({ title, audio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isError, setIsError] = useState(false);

  const audioRef = useRef(null);
  const isCurrentlyPlaying = useRef(null);

  const handlePlayPause = () => {
    const audioElement = audioRef.current;

    if (isPlaying) {
      audioElement.pause();
    } else {
      if (isCurrentlyPlaying.current) {
        isCurrentlyPlaying.current.pause();
      }
      audioElement.play();
    }

    setIsPlaying(!isPlaying);
    isCurrentlyPlaying.current = isPlaying ? null : audioElement;
  };

  const handleAudioError = () => {
    setIsPlaying(false);
    setIsError(true);
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleAudioEnded = () => {
      setIsPlaying(false);
    };

    audioElement.addEventListener("ended", handleAudioEnded);
    audioElement.addEventListener("error", handleAudioError);

    return () => {
      audioElement.removeEventListener("ended", handleAudioEnded);
      audioElement.removeEventListener("error", handleAudioError);
    };
  }, []);

  return (
    <div className={styles.audio}>
      <div className={styles.audio_area}>
        <div className={styles.audio_player} onClick={handlePlayPause}>
          <img
            src={isPlaying ? images.pause : images.play}
            alt={isPlaying ? "pause" : "play"}
            className={styles.play_button}
          />
          <audio preload="none" ref={audioRef}>
            <source src={audio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <div className={styles.audio_symbol}>
          <WaveAnimation isPlaying={isPlaying} />
        </div>
      </div>
      {isError && (
        <p className={styles.audio_error}>
          Error loading the audio. Please check the link.
        </p>
      )}
      <p className={styles.audio_title}>{title}</p>
    </div>
  );
};

export default AudioCard;
