import { images } from ".";

const automobile_features = [
  {
    id: 1,
    title: "Human like AI Voice Agents",
    description: "",
    image: images?.ai_voice_agents,
  },
  {
    id: 2,
    title: "AI that plugs into your software and answers your calls.",
    description: "",
    image: images?.ai_voice_analytics,
  },
  {
    id: 3,
    title: "Perform custom tasks like sending reminders, transfer calls etc.",
    description:
      "Perform custom tasks like sending reminders, transfer calls etc.",
    image: images?.ai_voice_integration,
  },
  {
    id: 4,
    title: "Custom AI Voicemail Drop",
    description: "",
    image: images?.ai_voice_api,
  },
];

const top_features = [
  {
    id: 1,
    title: "Inbound/Outbound campaigns",
    description: "You can easily create both inbound or outbound campaigns",
  },
  {
    id: 2,
    title: "Complex querry handling",
    description:
      "AI agents can easily answer complex questions be it from a document or a custom link",
  },
  {
    id: 3,
    title: "Voice mail drop",
    description:
      "You can easily configure personalised VM drops with AI agents",
  },

  {
    id: 4,
    title: "Sentiment Analysis",
    description:
      "Analyse conversational data to unlock unique insights by detecting customer sentiments",
  },
  {
    id: 5,
    title: "Workflows (demo booking, call transfers and more...)",
    description:
      "You can schedule a demo or transfer a call with advanced workflows",
  },
  {
    id: 6,
    title: "SMS and Emails",
    description:
      "Send customised SMS and emails for appointment confirmations, reminders etc",
  },
];

const video_links = ["https://www.youtube.com/embed/w_wWR8wcdDU"];

export default { automobile_features, top_features, video_links };
