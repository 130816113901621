import styles from "./BookDemo.module.scss";

import {
  powerDialBookDemo as book_demo,
  powerDialPartners as partners,
} from "../../../../constants";

import { Button } from "../../../../components";

const BookDemo = () => {
  return (
    <div>
      <div className={styles.book_demo}>
        <div className={styles.book_demo_text}>
          <h1 className={styles.book_demo_title}>
            Try our <span>AI Power Dialer</span> today
          </h1>
          <p className={styles.book_demo_desc}>
            Join the future of cold calling
          </p>
        </div>
        <div className={styles.book_demo_body}>
          <div className={styles.blur_effect}></div>
          <div className={styles.book_demo_body_left}>
            <div>
              <h1 className={styles.book_demo_left_title}>Book a DEMO</h1>
              <p className={styles.book_demo_left_dec}>
                Become an early adopter
              </p>
            </div>
            <Button
              text={"Get started"}
              link={"https://calendly.com/ai-calls-rdv/30min"}
            />
          </div>
          <div className={styles.book_demo_body_right}>
            <div className={styles.book_demo_content}>
              {book_demo.map((demo) => (
                <div className={styles.book_demo_benefits}>
                  <p>
                    <span>{demo.title}</span> {demo.description}
                  </p>
                </div>
              ))}
            </div>
            <div className={styles.book_demo_partners}>
              {partners.map((partner) => (
                <img src={partner.logo} alt="partner" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
