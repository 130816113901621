// import { motion } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";

import { images, audio_sample } from "../../../../constants";
import { Button, Navbar, WaveAnimation } from "../../../../components";

import styles from "./Header.module.scss";

const Header = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(null);
  const isCurrentlyPlaying = useRef(null);

  const handlePlayPause = () => {
    const audioElement = audioRef.current;

    if (isPlaying) {
      audioElement.pause();
    } else {
      if (isCurrentlyPlaying.current) {
        isCurrentlyPlaying.current.pause();
      }
      audioElement.play();
    }

    setIsPlaying(!isPlaying);
    isCurrentlyPlaying.current = isPlaying ? null : audioElement;
  };

  const handleSignup = () => {
    window.location.href = "https://app.aicalls.io/signup";
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleAudioEnded = () => {
      setIsPlaying(false);
    };

    const handleAudioError = (error) => {
      console.error("Error occurred while loading the audio:", error);
      setIsPlaying(false);
    };

    const handleAudioCanPlayThrough = () => {
      if (isPlaying) {
        audioElement.play();
      }
    };

    audioElement.addEventListener("ended", handleAudioEnded);
    audioElement.addEventListener("error", handleAudioError);
    audioElement.addEventListener("canplaythrough", handleAudioCanPlayThrough);

    return () => {
      audioElement.removeEventListener("ended", handleAudioEnded);
      audioElement.removeEventListener("error", handleAudioError);
      audioElement.removeEventListener(
        "canplaythrough",
        handleAudioCanPlayThrough,
      );
    };
  }, [isPlaying]);
  return (
    <div className={styles.header}>
      <div className={styles.main}>
        <Navbar />
        <div className={styles.hero}>
          <div className={styles.hero_left}>
            <p className={styles.hero_tagline}>
              Improve Customer Satisfaction Score
            </p>
            <h1 className={styles.hero_headline}>
              AI Voice Agents to boost your
              <span> service </span> <span>appointments</span>
            </h1>
            <p className={styles.hero_desc}>
              Talk to your customers 24x7, answer all their queries, book and
              confirm appointments on autopilot
            </p>
            <div className={styles.header_buttons}>
              <button className={styles.hero_btn} onClick={handlePlayPause}>
                <div className={styles.audio}>
                  <div className={styles.audio_player}>
                    <img
                      src={isPlaying ? images.pause : images.play}
                      alt={isPlaying ? "pause" : "play"}
                      className={styles.play_button}
                    />
                    <audio ref={audioRef}>
                      <source
                        src={
                          "https://s3.eu-west-3.amazonaws.com/thesales.io-public/ai_calls_landing_assets/CustomerOutboundcall-compressed.mp3"
                        }
                        type="audio/mpeg"
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>

                <div className={styles.audio_symbol}>
                  {isPlaying ? (
                    <WaveAnimation
                      isPlaying={isPlaying}
                      bgcolor1={"#F8F8FF"}
                      bgcolor2={"#FFFFFF"}
                      height={"20px"}
                    />
                  ) : (
                    <p className={styles.hero_btn_text}>
                      Live AI Conversations
                    </p>
                  )}
                </div>
              </button>

              <Button
                text={"Signup"}
                link={"https://app.aicalls.io/signup"}
                width={"10.8rem"}
                borderRadius={"2rem"}
              />
            </div>
          </div>
          <div className={styles.hero_right}>
            <img src={images.car_rent} alt="hero" className={styles.hero_img} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
