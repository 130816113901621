import React, { Suspense } from "react";

// styles
import styles from "./Landing.module.scss";

// components
import { AiFeatures, Skeleton } from "../../components";

// consants
import { aiautodial } from "../../constants";

// sections
import {
  Header,
  TopFeatures,
  Features,
  Working,
  Samples,
  Statistics,
  Faqsection,
  BookDemo,
  Video,
  CallToAction,
  Footer,
  TrustedPartners,
} from "./sections";

const Landing = () => {
  return (
    <div className={styles.landing}>
      <Header />
      {/* <AiFeatures features={aiautodial?.aiautodial_features} /> */}
      <TopFeatures />
      <Features />
      <Working />
      <Suspense fallback={<Skeleton className={styles.loader} />}>
        <Samples />
        <Statistics />
        <Faqsection />
        <BookDemo />
        <Video />
      </Suspense>
      <CallToAction />
      <TrustedPartners />
      <Footer />
    </div>
  );
};

export default Landing;
