import React from "react";
import styles from "./WaveAnimation.module.scss";

export default function WaveAnimation({
  isPlaying,
  bgcolor1,
  bgcolor2,
  height,
}) {
  const waveClass = isPlaying ? `${styles.box} ${styles.active}` : styles.box;
  const bgStyle = {
    background:
      bgcolor1 && bgcolor2
        ? `linear-gradient(40deg, ${bgcolor1} 20%, ${bgcolor2} 100%) no-repeat`
        : "linear-gradient(40deg, #9234ea 20%, #841ee3 100%) no-repeat",
  };
  return (
    <div
      className={`${styles["box-container"]} flex`}
      style={{
        height: height ? height : "40px",
      }}
    >
      <div className={`${waveClass} ${styles.box1}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box2}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box3}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box4}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box5}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box6}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box7}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box2}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box3}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box4}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box5}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box6}`} style={bgStyle}></div>
      <div className={`${waveClass} ${styles.box7}`} style={bgStyle}></div>
    </div>
  );
}
