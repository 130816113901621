import { powered } from "../../../../constants";

import styles from "./PoweredPartners.module.scss";

const PoweredPartners = () => {
  return (
    <div className={styles.partners}>
      <div className={styles.partners_title}>
        <p>Powered by</p>
      </div>
      <div className={styles.partners_logos}>
        {powered.map((partner) => (
          <div className={styles.partners_logo} key={partner.id}>
            <img src={partner.logo} alt="partner" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PoweredPartners;
