import { images } from ".";

const integration_crms = [
  {
    id: 1,
    name: "GHL",
    logo: images.ghl,
  },

  {
    id: 3,
    name: "Zapier",
    logo: images.zapier,
  },
  {
    id: 4,
    name: "Salesforce",
    logo: images.salesforce,
  },
  {
    id: 2,
    name: "Freshsales",
    logo: images.freshsales,
  },
];

const aiautodial_features = [
  {
    id: 1,
    title: "Human like AI Voice Agents",
    description: "",
    image: images?.ai_voice_agents,
  },
  {
    id: 2,
    title: "CRM integrations",
    description: "",
    image: images?.ai_voice_analytics,
  },
  {
    id: 3,
    title: "Inbound and Outbound campaigns",
    description: "",
    image: images?.ai_voice_integration,
  },
  {
    id: 4,
    title: "Custom and advanced workflows",
    description: "",
    image: images?.ai_voice_api,
  },
  {
    id: 5,
    title: "API access",
    description: "",
    image: images?.ai_voice_api,
  },
  {
    id: 5,
    title: "Custom AI Voicemail Drop",
    description: "",
    image: images?.ai_voice_api,
  },
];

export default { integration_crms, aiautodial_features };
