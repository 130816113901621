import styles from "./HorizontalCard.module.scss";

const HorizontalCard = ({ img, title, color, step }) => {
  return (
    <div className={styles.card}>
      <div
        className={styles.card_icon_container}
        style={{
          backgroundColor: color,
        }}
      >
        <img src={img} alt="card" className={styles.card_icon} />
      </div>
      <p className={styles.card_title}>
        {step} {title}
      </p>
    </div>
  );
};

export default HorizontalCard;
