import { lazy } from "react";

const BookDemo = lazy(() => import("./BookDemo/BookDemo"));
const Working = lazy(() => import("./Working/Working"));
const Features = lazy(() => import("./Features/Features"));
const Footer = lazy(() => import("./Footer/Footer"));
const Header = lazy(() => import("./Header/Header"));
const Faqsection = lazy(() => import("./Faqsection/Faqsection"));
const Samples = lazy(() => import("./Samples/Samples"));
const Partners = lazy(() => import("./Partners/Partners"));
const Statistics = lazy(() => import("./Statistics/Statistics"));
const Video = lazy(() => import("./Video/Video"));
const CallToAction = lazy(() => import("./CallToAction/CallToAction"));
const TopFeatures = lazy(() => import("./TopFeatures/TopFeatures"));
const TrustedPartners = lazy(() => import("./PoweredPartners/PoweredPartners"));

export {
  BookDemo,
  Working,
  Features,
  Footer,
  Header,
  Samples,
  Partners,
  Statistics,
  Video,
  CallToAction,
  Faqsection,
  TopFeatures,
  TrustedPartners,
};
