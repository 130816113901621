import React from "react";

import styles from "./CallToAction.module.scss";
import { Button } from "../../../../components";

const CallToAction = () => {
  return (
    <div className={styles.cta}>
      <div className={styles.bg}></div>
      <div className={styles.cta_text}>
        <h1 className={styles.cta_title}>
          Ready to build your <span>AI agents?</span>
        </h1>
        <p className={styles.cta_desc}>
          Talk to one of our founder face 2 face and get a real-time demo of our
          AI voice agent
        </p>
      </div>
      <Button
        text={"Schedule a demo"}
        link={"https://calendly.com/paul1506/introduction-to-ai-calls"}
        padding={"0.5rem 1.5rem"}
      />
    </div>
  );
};

export default CallToAction;
