import React from "react";
import styles from "./Timeline.module.scss";

const Timeline = () => {
  return (
    <div className={styles.timeline}>
      <div className={styles.line} />
      <div className={styles.circle1} />
      <div className={styles.circle2} />
      <div className={styles.circle3} />
      <div className={styles.circle4} />
    </div>
  );
};

export default Timeline;
