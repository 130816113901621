import styles from "./AiAutoDial.module.scss";

// components
import { AiFeatures, Navbar } from "../../components";

// feature
import { aiautodial } from "../../constants";

// constants
const navOption = [
  {
    title: "Login",
    path: "/login",
    link: "https://app.aicalls.io/login",
  },
  {
    title: "Signup",
    path: "/signup",
    link: "https://app.aicalls.io/signup",
  },
];

const AiAutoDial = () => {
  return (
    <div className={styles.ai_ad}>
      <Navbar nav_options={navOption} />
      <AiFeatures
        title="Try our AI Voice agent"
        custom_styles={styles.custom_aif}
        features={aiautodial?.aiautodial_features}
      />
    </div>
  );
};

export default AiAutoDial;
