import styles from "./Statistics.module.scss";

import { images } from "../../../../constants";

import { Button } from "../../../../components";

const Statistics = () => {
  return (
    <div className={styles.stats}>
      <div className={styles.stats_text}>
        <h1 className={styles.stats_title}>
          Boost your cold calling with <span>4x AI Dialer</span>
        </h1>
        <p className={styles.stats_desc}>
          No more wasting time with manual dials. Use our powerful multiple
          dialer and reach more prospects in less time
        </p>
      </div>
      <div className={styles.stats_body}>
        <div className={styles.stats_left}>
          <img src={images.performance} alt="performance" />
        </div>
        <div className={styles.stats_right}>
          <h1 className={styles.stats_title}>
            Quick ROI with our AI Power Dialer
          </h1>
          <p className={styles.stats_desc}>
            Maximize your sales team's productivity with our AI Power Dialer.
            <br />
            <br />
            Increase prospecting, conversations, opportunities, and sales.
            <br />
            <br />
            Experience rapid return on investment. See real results in no time!
          </p>
          <Button
            text={"Get started"}
            link={"https://calendly.com/ai-calls-rdv/30min"}
          />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
