import React from "react";
import styles from "./Accordion.module.scss";

import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";

const Accordion = ({
  header,
  isOpen,
  content,
  setAccordionActive,
  accIndex,
}) => {
  const accordionClass = isOpen
    ? `${styles.accordion} ${styles.open}`
    : styles.accordion;

  return (
    <div className={accordionClass}>
      <div className={styles.header}>
        <div className={`${isOpen && styles.purple} ${styles.title}`}>
          {header}
        </div>
        <div
          onClick={() => setAccordionActive(isOpen ? 0 : accIndex)}
          className={styles.icon}
        >
          {isOpen ? <Minus /> : <Plus />}
        </div>
      </div>
      {isOpen && <div className={styles.content}>{content}</div>}
    </div>
  );
};

export default Accordion;
