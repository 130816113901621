//partner companies
import logo1 from "../assets/img/logo1.svg";
import logo2 from "../assets/img/logo2.svg";
import logo3 from "../assets/img/logo3.svg";
import logo4 from "../assets/img/logo4.svg";

//sections images
import ai from "../assets/img/ai.svg";
import powerdial_ai from "../assets/img/powerdial_ai.svg";
import performance from "../assets/img/stats.svg";
import xp from "../assets/img/xp.svg";
import hero from "../assets/img/hero.svg";
import powerdial_hero from "../assets/img/powerdial_hero.svg";
import header_bg from "../assets/img/header-bg.svg";
import quick_roi from "../assets/img/quick_roi.svg";
import cta from "../assets/img/cta.svg";
import prequalify from "../assets/img/prequalify.svg";

//icons for features
import availability from "../assets/icons/availability.svg";
import flash from "../assets/icons/flash.svg";
import language from "../assets/icons/language.svg";
import search from "../assets/icons/search.svg";

//icons for working section
import customer from "../assets/icons/customer.svg";
import stats from "../assets/icons/stats.svg";
import microphone from "../assets/icons/microphone.svg";
import industry from "../assets/icons/industry.svg";

//icon for sample audio player
import play from "../assets/icons/play.svg";
import pause from "../assets/icons/pause.svg";

//icons for footer
import linkedin from "../assets/icons/linkedin.svg";

//logo
import aicalls_logo from "../assets/logo.svg";
import elevenlabs_logo from "../assets/icons/elevenlabs.svg";

// crms and integrations
import ghl from "./../assets/integrations/ghl.svg";
import freshsales from "./../assets/integrations/freshsales.svg";
import zapier from "./../assets/integrations/zapier.svg";
import salesforce from "./../assets/integrations/salesforce.svg";
import call_icon from "./../assets/icons/call.svg";

// automobile
import stat from "./../assets/img/stat.png";
import company from "./../assets/img/company.png";
import car_rent from "./../assets/img/car-rent.png";

export default {
  logo1,
  logo2,
  logo3,
  logo4,
  ai,
  powerdial_ai,
  performance,
  xp,
  hero,
  powerdial_hero,
  availability,
  flash,
  language,
  search,
  customer,
  stats,
  microphone,
  industry,
  play,
  pause,
  header_bg,
  quick_roi,
  cta,
  linkedin,
  aicalls_logo,
  prequalify,
  elevenlabs_logo,
  ghl,
  freshsales,
  zapier,
  salesforce,
  call_icon,
  stat,
  company,
  car_rent,
};
