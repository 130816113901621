// import { motion } from "framer-motion";

import { images } from "../../../../constants";
import { Navbar, Button } from "../../../../components";

import Partners from "../Partners/Partners";

import styles from "./Header.module.scss";

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.main}>
        <Navbar />
        <div className={styles.hero}>
          <div className={styles.hero_left}>
            <p className={styles.hero_tagline}>
              Unlock the Power of AI POWER DIALER
            </p>
            <h1 className={styles.hero_headline}>
              Boost conversations with increased <span>speed </span>{" "}
              <span>and effeciency</span>
            </h1>
            <p className={styles.hero_desc}>
              Pre-Qualify your database, reach to more prospects and book more
              meetings.
            </p>
            <Button
              text={"BOOK DEMO"}
              link={"https://calendly.com/ai-calls-rdv/30min"}
            />
          </div>
          <div className={styles.hero_right}>
            <img
              src={images.powerdial_hero}
              alt="hero"
              className={styles.hero_img}
            />
          </div>
        </div>
      </div>
      <Partners />
    </div>
  );
};

export default Header;
