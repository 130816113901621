import React, { Suspense } from "react";

// components
import { AiFeatures, Skeleton } from "../../components";

// sections
import {
  Header,
  TopFeatures,
  Video,
  CallToAction,
  Footer,
  PoweredPartners,
  ImgContentSection,
} from "./sections";

import { automobile, images } from "../../constants";

import styles from "./AutoMobile.module.scss";

const AutoMobile = () => {
  return (
    <div className={styles.am}>
      <div>
        <Header />
        <AiFeatures
          desc="With AI CALLS you can create custom AI Voice agents for your
        car dealership. Setting up AI voice agent is simple and easy."
          features={automobile?.automobile_features}
          showCrm={false}
          showCampaignTypes={true}
        />
      </div>
      <TopFeatures features={automobile?.top_features} />
      <ImgContentSection
        image={images.stat}
        title={"Turbocharge your CSI"}
        description={
          "Use human like AI Voice Agents to get faster feedback and take immediate actions to improve CSI."
        }
      />
      <ImgContentSection
        reverse={true}
        image={images.company}
        title={"Bring your own software."}
        description={
          "Use Zapier to quickly integrate your DMS, CRM, and other software. We can also work with you to directly integrate AI Calls with your favorite software."
        }
      />
      <Video />
      <CallToAction />
      <PoweredPartners />
      <Footer />
    </div>
  );
};

export default AutoMobile;
