import { partners } from "../../../../constants";

import styles from "./Partners.module.scss";

const Partners = () => {
  return (
    <div className={styles.partners}>
      <div className={styles.partners_title}>
        <p>Trusted by global business partners</p>
      </div>
      <div className={styles.partners_logos}>
        {partners.map((partner) => (
          <div className={styles.partners_logo} key={partner.id}>
            <img src={partner.logo} alt="partner" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
