import React from "react";

import styles from "./Buttons.module.scss";

const Button = ({
  text,
  padding,
  link,
  width,
  borderRadius,
  target,
  icon,
  onClick,
}) => {
  return (
    <a
      href={link ? link : null}
      target={
        target === "_blank" || link?.includes("http") ? "_blank" : "_self"
      }
      rel="noreferrer"
    >
      <button
        className={styles.button}
        onClick={onClick}
        style={{
          padding: padding ? padding : "0.5rem 1rem",
          ...(width && { width }),
          ...(borderRadius && { borderRadius }),
        }}
      >
        {icon && (
          <div className={styles.icon}>
            <img src={icon} alt="icon" />
          </div>
        )}
        <p className={styles.text}>{text ? text : "Button"}</p>
      </button>
    </a>
  );
};

export default Button;
