import React from "react";

import { Routes, Route } from "react-router-dom";

import {
  AiAutoDial,
  Landing,
  PowerDial,
  PrivacyPolicy,
  AutoMobile,
} from "./pages";

// toast setup
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/power-dial" element={<PowerDial />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/ai-auto-dial" element={<AiAutoDial />} />
        <Route path="/automobile" element={<AutoMobile />} />
        <Route path="*" element={<Landing />} />
      </Routes>
    </div>
  );
};

export default App;
