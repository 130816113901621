// libraries
import { useEffect } from "react";

// components
import Input from "../../../Input/Input";
import Button from "./Button/Button";

// constants
import { images } from "../../../../constants";

// styles
import styles from "./Form.module.scss";

const Form = ({
  formData,
  setFormData,
  handleSubmit,
  handlePhoneNumber,
  loading,
  campaignInfo,
  showCampaignTypes,
}) => {
  useEffect(() => {}, [formData.isAgreed]);

  return (
    <div className={styles.form}>
      <h1>
        Try a live call from our <span> AI voice agent</span>
      </h1>
      {showCampaignTypes && (
        <div className={styles.campaigns}>
          {Object.values(campaignInfo).map((campaign, index) => (
            <div
              key={index}
              className={
                formData.campaignInfo?.campaign_id === campaign?.campaign_id
                  ? `${styles.campaign} ${styles.active}`
                  : `${styles.campaign}`
              }
              onClick={() => {
                setFormData({ ...formData, campaignInfo: campaign });
              }}
            >
              <h4>{campaign?.campaign_name}</h4>
            </div>
          ))}
        </div>
      )}
      <div className={styles.inputs}>
        <Input
          name={formData.name}
          value={formData.name}
          heading="Full Name"
          placeholder="ex, John Doe"
          type="text"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className={
            // error if name is not valid
            formData.name.length < 3 && styles.error
          }
        />
        <Input
          name={formData.email}
          value={formData.email}
          heading="Email"
          placeholder="ex, john@gmail.com"
          type="email"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className={
            // error if email is not valid
            !formData.email.includes("@") && styles.error
          }
          input_info="Email should contain @ symbol"
        />
        <Input
          name={formData.phone}
          value={formData.phone}
          heading="Phone Number"
          placeholder="+19713207143 (Phone should contain country code)"
          type="text"
          onChange={(e) => handlePhoneNumber(e)}
          className={
            // error if phone number is not valid
            formData.phone.length < 11 && styles.error
          }
        />
      </div>
      <div className={styles.check}>
        {/* checkbox */}
        <Input
          name={formData.isAgreed}
          value={formData.isAgreed}
          type="checkbox"
          onChange={(e) =>
            setFormData({ ...formData, isAgreed: e.target.checked })
          }
          id="checkbox"
        />
        <p>I agree to receive calls from AI Voice Agent</p>
      </div>
      <div className={styles.btns}>
        <Button
          text={loading ? "Creating..." : "Get a call from AI Voice Agent"}
          padding="1rem 2rem"
          width="100%"
          borderRadius="4rem"
          icon={images.call_icon}
          onClick={handleSubmit}
          custom_styles={
            // disable button if form is not valid
            !formData.isAgreed ||
            !formData.email.includes("@") ||
            formData.phone.length < 11 ||
            !formData?.campaignInfo?.campaign_id ||
            formData.name.trim().length < 3
              ? styles.disabled
              : ""
          }
        />
      </div>
    </div>
  );
};

export default Form;
