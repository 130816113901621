import { images } from "../../../../constants";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <img src={images.aicalls_logo} alt="logo" className={styles.logo} />
      <a href="https://www.linkedin.com/company/ai-calls/">
        <img src={images.linkedin} alt="linkedin" className={styles.linkedin} />
      </a>
      <p className={styles.footer_desc}>
        Copyright © AI Calls 2023 - Present | All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
